@import "../../styles/typography";
@import "../../styles/themes";
@import "../../styles/global-css-settings";
@import "../../styles/states";

@mixin active-step-dot {
  @include theme-prop(background, primary-color);
  transform: scale(1.3);
}
.monday-style-steps {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_numbers {
      margin: 0 $spacing-small;
      @include font-default;
    }

    &_dot {
      @include reset-button;
      width: 6px;
      height: 6px;
      cursor: pointer;
      border-radius: 50%;
      @include theme-prop(background, ui-border-color);
      @include focus-style;
      transform-origin: center;
      transform: scale(1);
      transition: transform var(--motion-productive-long) var(--motion-timing-transition), background var(--motion-productive-long) var(--motion-timing-transition);
      &:hover {
        transform: scale(1.3);
      }
      &--is-active {
        @include active-step-dot;
      }
    }

    &_gallery {
      margin: 0 $spacing-small;
      display: flex;
      align-items: center;

      .monday-style-steps-header_dot {
        margin-right: $spacing-small;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &-command {
    display: flex;
    align-items: center;

    &_icon {
      @include theme-prop(color, icon-color);
      width: 15.5px;
      height: 15.5px;

    }

    &--backward {
      margin-right: $spacing-small;
      flex-direction: row-reverse;
      .monday-style-steps-command_icon {
        margin-right: $spacing-small-medium;
      }
    }

    &--forward {
      margin-left: $spacing-small;
      .monday-style-steps-command_icon {
        margin-left: $spacing-small-medium;
      }
    }


  }
  &--content-on-top {
    .monday-style-steps-header {
      margin-top: $spacing-medium;
    }
  }
  &--content-on-bottom {
    .monday-style-steps-header {
      margin-bottom: $spacing-small;
    }
  }
  &--on-primary {
    .monday-style-steps {
      &-header {
        &_numbers {
          @include theme-prop(color, text-color-on-primary);
        }

        &_dot {
          @include theme-prop(background, primary-hover-color);
          @include focus-style-on-primary;

          &--is-active {
            @include theme-prop(background, text-color-on-primary);
          }
        }
      }
      &-command {
        &_icon {
          @include theme-prop(color, text-color-on-primary);
        }
      }
    }
  }
}
