@import "../../../styles/global-css-settings";
@import "../../../styles/typography";
@import "../../../styles/themes";

.monday-style-link.monday-style-alert-banner-link {
  @include theme-prop(color, text-color-on-primary);
  text-decoration: underline;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.monday-style-alert-banner-link-dark-background {
  .monday-style-link.monday-style-alert-banner-link {
    @include theme-prop(color, text-color-on-inverted);
  }
}

.monday-style-alert-banner-link-margin-left {
  margin-left: $spacing-small;
}
