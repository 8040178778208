@import "./theme-mixin";

$box-shadow-small: 0 4px 8px -1px rgba(0, 0, 0, 0.2);
$box-shadow-medium: 0px 6px 20px -2px rgba(0, 0, 0, 0.2);
$box-shadow-large: 0px 15px 50px -10px rgba(0, 0, 0, 0.3);

$border-radius-extra-small: 2px;
$border-radius-small: 4px;
$border-radius-medium: 8px;
$border-radius-big: 16px;

$spacing-xxs: 2px;
$spacing-xs: 4px;
$spacing-xs-small: 6px;
$spacing-small: 8px;
$spacing-small-medium: 12px;
$spacing-medium: 16px;
$spacing-large: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;
$spacing-xxxl: 64px;

$expand-animation-timing: cubic-bezier(0, 0, 0.35, 1);

// Grid auto fit sizes
$grid-auto-fit-cell-width-small: 120px;
$grid-auto-fit-cell-width-medium: 180px;
$grid-auto-fit-cell-width-large: 240px;
$grid-auto-fit-cell-width-xl: 300px;

@mixin box-shadow-xs() {
  @include theme-prop(box-shadow, box-shadow-xs);
}

@mixin box-shadow-small() {
  @include theme-prop(box-shadow, box-shadow-small);
}

@mixin box-shadow-medium() {
  @include theme-prop(box-shadow, box-shadow-medium);
}

@mixin box-shadow-large() {
  @include theme-prop(box-shadow, box-shadow-large);
}

@mixin hidden-element() {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

// Grid auto fit
// - This mixin defines a grid with auto fit repeat cells using min-max funtion.
// -- This allows grid to accommodate container width without media queries.
// -- it uses a repeat grid function with auto fix and minmax.
// -- grid adaptation is due to the min value along with auto-fit and 1fr max value.

// @params:
// - $grid-gap: null , defines grid "gap" attribute.
// - $grid-column-gap: null,  defines grid "column-gap" attribute.
// - $grid-row-gap: null,  defines grid "row-gap" attribute.
// - $grid-cell-min-width: {mandatory}, defined min value in for grid-template-columns
// - $grid-cell-array-calc: {mandatory}, defined max num of items using calc


@mixin grid-auto-fit($grid-gap: null, $grid-column-gap: null, $grid-row-gap: null, $grid-cell-min-width, $grid-cell-array-calc:null) {
  display: grid;
  grid-template-columns:repeat(auto-fit, minmax(clamp(#{$grid-cell-array-calc}, #{$grid-cell-min-width}, 100%), 1fr));
  gap: $grid-gap;
  column-gap: $grid-column-gap;
  row-gap: $grid-row-gap;
}
