.modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_inner {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    min-height: 250px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.close_button {
    cursor: pointer;
}

.close_button:hover {
    color:rgb(248, 48, 48);
}