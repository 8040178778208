@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/states";
@import "../../styles/global-css-settings";

.expand-collapse--wrapper {
  .expand-collapse {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include theme-prop(color, primary-text-color);
    background-color: transparent;
    border: 1px solid;
    @include theme-prop(border-color, ui-border-color);
    box-sizing: border-box;
    border-radius: $border-radius-small;
  }

  .expand-collapse__header {
    @include reset-button();

    padding: $spacing-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      @include theme-prop(background-color, primary-background-hover-color);
    }

    .expand-collapse__header-content {
      margin: 0px;
    }

    .icon_component {
      @include theme-prop(color, icon-color);
    }
  }

  .expand-collapse__header--open {
    border-bottom: 1px solid;
    @include theme-prop(border-color, ui-border-color);
  }

  .expand-collapse__content {
    padding: $spacing-medium;
  }

  .expand-collapse__section {
    width: 100%;
  }

  .animate-icon-open {
    transform: rotate(-180deg);
    transition: transform var(--motion-expressive-short) var(--motion-timing-transition);
  }

  .animate-icon-close {
    transform: rotate(-360deg);
    transition: transform var(--motion-expressive-short) var(--motion-timing-transition);
  }

  .animate-expand-collapse__content {
    animation: expandContentAnimation var(--motion-productive-medium) var(--motion-timing-enter);
  }

  @keyframes expandContentAnimation {
    0% {
      opacity: 0;
      transform: translateY(var(--dropdown-menu-fadinY, -75px));
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
