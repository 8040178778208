@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";
@import "../../styles/states";

.monday-style-link {
  display: flex;
  align-items: center;
  @include theme-prop(color, link-color);
  @include font-link();
  text-decoration: none;
  writing-mode: horizontal-tb;
  @include focus-style();
  &:hover {
    .monday-style-link--text {
      text-decoration: underline;
    }
  }
  &.inline-text {
    display: inline-flex;
    align-items: unset;
  }
  &.inherit-font-size {
    font-size: inherit;
    line-height: inherit;
  }
  &--icon-start {
    line-height: 24px;
    margin-right: $spacing-small;
  }
  &--icon-end {
    line-height: 24px;
    margin-left: $spacing-small;
  }
}

@supports (margin-inline-start: initial) {
  .monday-style-link {
    &--icon-start {
      line-height: 24px;
      margin-right: 0;
      margin-inline-end: $spacing-small;
    }
    &--icon-end {
      line-height: 24px;
      margin-left: 0;
      margin-inline-start: $spacing-small;
    }
  }
}
