@import "../../../../styles/themes";
@import "../../../../styles/typography";

.chip-with-input-wrapper {
  display: flex;
  min-height: 30px;
}
.placeholder-container {
  margin-left: 6px;
}

.multiselect-chip-multi-line {
  margin: 2px;
}

.multiselect-chip-single-line {
  margin: 5px 2px;
}

.multiselect-chip-disabled {
  background-color: var(--primary-background-hover-color) !important;
}

.value-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.value-container-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  &.without-placeholder {
    margin-left: 6px;
  }
}

.value-container-dialog-content {
  max-width: 300px;
}
