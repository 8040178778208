@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.dropdown-wrapper {
  .fast-option {
    display: flex;
    align-content: center;
    @include theme-prop(color, primary-text-color);
    cursor: pointer;
    border-radius: $border-radius-small;
    margin: 0 8px;
    padding: 0 8px;
    transition: all var(--motion-productive-medium) var(--motion-timing-enter);

    &:disabled {
      background-color: transparent;
      @include theme-prop(color, disabled-text-color);
      cursor: not-allowed;
    }

    &-disabled {
      background-color: transparent;
      @include theme-prop(color, disabled-text-color);
      cursor: not-allowed;
    }

    &-focused {
      @include theme-prop(color, primary-text-color);
      @include theme-prop(background-color, primary-selected-color);
      user-select: none;
      box-sizing: border-box;

      &:hover {
        @include theme-prop(color, primary-text-color);
        @include theme-prop(background-color, primary-background-hover-color);
      }
    }

    &-selected {
      @include theme-prop(color, primary-color);
      @include theme-prop(background-color, primary-selected-color);
      cursor: pointer;
    }
  }
}

